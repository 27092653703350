<template>
    <main class="gift-card-payment-result page_bg_grey blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">

        <div v-if="!transactionStatus || transactionStatus <= 1" class="text-center">
            In progress...
        </div>

        <div v-else-if="transactionStatus == 3">

            <section v-if="transactionReceiptData" class="success__info">
                <h1 class="title title__h1 success__title">{{ $t("Invitation accepted") }}</h1>
                <div class="success__time">
                    <span class="success__time-text">{{ $t("Date") }}:</span>
                    <span class="success__time-value">{{ transactionReceiptData.transDateTime }}</span>
                </div>
                <div class="success__place">
                    <span class="success__place-text">{{ $t("Place") }}:</span>
                    <span class="success__place-value">{{ $t("In Tel Aviv") }}</span>
                </div>
                <div class="success__agent">
                    <span class="success__agent-text">{{ $t("Agent") }}:</span>
                    <span class="success__agent-value">{{ $t("Haim Leviathan") }}</span>
                </div>
            </section>

            <section v-if="transactionReceiptData" class="success__bill">
                <div class="success__bill-head">
                    <div class="success__bill-wrapper">
                        <span class="success__text">{{ $t("Invoice") }}</span>
                        <span class="success__bill-number">{{ ' ' + transactionReceiptData.transId + ' ' }}</span>
                    </div>
                    <button @click="printReceipt" class="button success__print">
                        <span class="success__print-span"></span>
                    </button>
                </div>

                <div class="success__body">
                    <div class="success__caption">
                        <span class="success__goods">{{ $t("Product") }}</span>
                        <span class="success__quantity">{{ $t("Quantity") }}</span>
                        <span class="success__price">{{ $t("Price") }}</span>
                    </div>

                    <div class="success__item">
                        <div class="success__item-wrapper">
                            <p class="text success__item-text">
                                {{ ' ' + transactionReceiptData.product + ' ' }}
                            </p>
                        </div>
                        <span class="success__quantity-value">1</span>
                        <div class="success__price-wrapper">
                            <span class="success__price-value">{{ ' ' + transactionReceiptData.price + ' ' }}</span>
                            <span class="success__current">{{ $t("NIS") }}</span>
                        </div>
                    </div>

                    <div class="success__total">
                        <div class="success__total-wrapper">
                            <span class="success__total-text">{{ $t("Total") }}</span>
                            <div class="success__amount">
                                <span class="success__amount-value">{{ ' ' + priceWithoutVAT + ' ' }}</span>
                                <span class="success__current">{{ $t("NIS") }}</span>
                            </div>
                        </div>

                        <div class="success__total-wrapper">
                            <span class="success__total-text">{{ $t("VAT") }}</span>
                            <div class="success__amount">
                                <span class="success__amount-value">{{ ' ' + priceVAT + ' ' }}</span>
                                <span class="success__current">{{ $t("NIS") }}</span>
                            </div>
                        </div>

                        <div class="success__total-wrapper success__total-wrapper_margin">
                            <span class="success__total-text">{{ $t("Total payment including VAT") }}</span>
                            <div class="success__amount success__amount_font_bold">
                                <span class="success__amount-value">{{ ' ' + price + ' ' }}</span>
                                <span class="success__current">{{ $t("NIS") }}</span>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

        </div>

        <div v-else-if="transactionStatus == 4 || transactionStatus == 2">
            {{ transactionDataStatusMessage ? transactionDataStatusMessage : 'Error' }}
        </div>

        <loading :active.sync="loading"
                 :can-cancel="false"
                 :is-full-page="true"></loading>
    </main>
</template>

<script>
    import {mapState, mapActions} from 'vuex'
    import appConfig from '../../appConfig'
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';

    export default {
        components: {
            Loading
        },
        name: 'GiftCardPaymentResult',
        data: () => {
            return {
                checkTransactionStatusInterval: null,
                loading: false
            }
        },
        mounted() {
        },
        destroyed() {
            this.clearCheckTransactionStatusInterval()
        },
        computed: {
            ...mapState({
                transactions_receipts: state => state.gift_card_payment.transactions_receipts,
                current_transaction_supplier_id: state => state.gift_card_payment.current_transaction_supplier_id,
                current_transaction_product_id: state => state.gift_card_payment.current_transaction_product_id,
                supplier_items: state => state.supplier.supplier_items,
                suppliers: state => state.supplier.suppliers,
                suppliers_products: state => state.supplier.suppliers_products,
                products: state => state.product.products,
                transactions_statuses: state => state.gift_card_payment.transactions_statuses,
                getTransactionReceiptInProgress: state => state.gift_card_payment.getTransactionReceiptInProgress,
            }),
            transactionId() {
                return this.$route.params.transaction_id
            },
            transactionSupplierId() {
                return this.current_transaction_supplier_id ? this.current_transaction_supplier_id : null
            },
            transactionProductId() {
                return this.current_transaction_product_id ? this.current_transaction_product_id : null
            },
            transactionReceiptData() {
                let transactionReceiptData = null;
                if (this.transactions_receipts && this.transactionId in this.transactions_receipts) {
                    transactionReceiptData = {...this.transactions_receipts[this.transactionId]}
                }

                return transactionReceiptData ? transactionReceiptData : null
            },
            transactionSupplierData() {
                let supplierDataFromSuppliersItemsData = null;
                if (this.supplier_items) {
                    for (let supplier_type in this.supplier_items) {
                        for (let suppliers_type_item of this.supplier_items[supplier_type]) {
                            if (suppliers_type_item.id == this.transactionSupplierId) {
                                supplierDataFromSuppliersItemsData = {...suppliers_type_item};
                                break;
                            }
                        }
                    }
                }

                let supplierDataFromSuppliersData = null;
                if (this.suppliers && this.transactionSupplierId in this.suppliers) {
                    supplierDataFromSuppliersData = {...this.suppliers[this.transactionSupplierId]}
                }

                let supplierData = supplierDataFromSuppliersData ? supplierDataFromSuppliersData : supplierDataFromSuppliersItemsData
                return supplierData ? supplierData : null
            },
            transactionProductData() {
                let productDataFromSuppliersData = null;

                if (this.suppliers_products) {
                    for (let supplier_id in this.suppliers_products) {
                        for (let supplier_product_type in this.suppliers_products[supplier_id]) {
                            for (let supplier_product of this.suppliers_products[supplier_id][supplier_product_type]) {
                                if (supplier_product.hasOwnProperty('id')
                                    && supplier_product.id == this.transactionProductId) {
                                    productDataFromSuppliersData = {...supplier_product}
                                    break;
                                }
                            }
                        }
                    }
                }

                let productDataFromProductsData = null;
                if (this.products && this.transactionProductId in this.products) {
                    productDataFromProductsData = {...this.products[this.transactionProductId]}
                }

                let productData = productDataFromProductsData ? productDataFromProductsData : productDataFromSuppliersData
                return productData ? productData : null
            },
            price() {
                return this.transactionReceiptData.price ? this.formatNumber(this.transactionReceiptData.price) : ''
            },
            priceVAT() {
                return this.price ? this.formatNumber(this.price * 0.17) : 0
            },
            priceWithoutVAT() {
                return this.price && this.priceVAT ? this.formatNumber(this.price - this.priceVAT) : this.price
            },
            transactionStatusData() {
                let transactionStatusData = this.transactionId in this.transactions_statuses ? this.transactions_statuses[this.transactionId] : null

                return transactionStatusData
            },
            transactionStatus() {
                let transactionStatus = this.transactionStatusData && this.transactionStatusData.hasOwnProperty('status') ? this.transactionStatusData.status : null

                return transactionStatus
            },
            transactionDataStatus() {
                let transactionDataStatus = this.transactionStatusData && this.transactionStatusData.hasOwnProperty('transactionData') && this.transactionStatusData.transactionData.hasOwnProperty('status') ? this.transactionStatusData.transactionData.status : null

                return transactionDataStatus
            },
            transactionDataStatusMessage() {
                let transactionDataStatusMessage = this.transactionStatusData && this.transactionStatusData.hasOwnProperty('transactionData') && this.transactionStatusData.transactionData.hasOwnProperty('comment') ? this.transactionStatusData.transactionData.comment : null

                return transactionDataStatusMessage
            }
        },
        created() {
            this.startStatusCheckingProcess()
        },
        watch: {
            transactionStatus(transactionStatus) {
                if (transactionStatus == 3) {
                    this.getTransactionReceipt({'transaction_id': this.transactionId})

                    if (this.transactionProductId) {
                        this.getProduct({'product_id': this.transactionProductId})
                    }

                    if (this.transactionSupplierId) {
                        this.getSupplier({'supplier_id': this.transactionSupplierId})
                    }
                }
            },
            checkTransactionStatusInterval(checkTransactionStatusInterval) {
                let show_loading = !!checkTransactionStatusInterval || this.getTransactionReceiptInProgress

                this.loading = show_loading
            },
            getTransactionReceiptInProgress(getTransactionReceiptInProgress) {
                let show_loading = !!this.checkTransactionStatusInterval || getTransactionReceiptInProgress

                this.loading = show_loading
            }
        },
        methods: {
            ...mapActions('gift_card_payment', {
                getTransactionReceipt: 'getTransactionReceipt'
            }),
            ...mapActions('product', {
                getProduct: 'getProduct'
            }),
            ...mapActions('supplier', {
                getSupplier: 'getSupplier'
            }),
            ...mapActions('gift_card_payment', {
                checkTransactionStatus: 'checkTransactionStatus'
            }),
            getApiURL() {
                return appConfig.apiUrl
            },
            printReceipt() {
                window.print();
            },
            formatNumber(number) {
                let numberFormatObject = new Intl.NumberFormat('en-US')
                return numberFormatObject.format(number)
            },
            startStatusCheckingProcess() {
                let obj = this
                if (obj.checkTransactionStatusInterval || !obj.transactionId
                    || obj.transactionStatus && obj.transactionStatus > 1 && obj.transactionStatus != 5) {
                    return
                }

                obj.checkTransactionStatusInterval = setInterval(async function () {
                    await obj.checkTransactionStatus({'transaction_id': obj.transactionId, 'phoneNumber': obj.phoneNumber}).then(
                        result_data => {
                            if (obj.transactionStatus > 1 && obj.transactionStatus != 5) {
                                obj.clearCheckTransactionStatusInterval()
                            }
                        },
                        error => {
                            obj.clearCheckTransactionStatusInterval()
                            alert('Error!')
                        }
                    )
                        .catch(error => {
                            obj.clearCheckTransactionStatusInterval()
                            alert('Error!')
                        })

                }, 3000);
            },
            clearCheckTransactionStatusInterval() {
                clearInterval(this.checkTransactionStatusInterval)
                this.checkTransactionStatusInterval = null
            }
        },
        updated() {
        },
        beforeRouteLeave(to, from, next) {
            if (this.checkTransactionStatusInterval) {
                const answer = window.confirm('Do you really want to leave? Payment in progress, you may lose progress!')
                if (answer) {
                    next()
                } else {
                    next(false)
                }
            } else {
                next()
            }
        }
    }
</script>

<style>
    @media print {
        header,
        aside,
        button.button.success__print {
            display: none !important;
        }
    }
</style>